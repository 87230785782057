<template>
  <v-app>
    <v-overlay
      :value="loadingApp"
      opacity="1"
      color="white"
      z-index="1000"
    >
      <v-progress-circular
        indeterminate
        color="brand"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-main>
      <v-app-bar
        v-if="!preview"
        absolute
        height="56"
      >
        <v-toolbar-title>WebUI</v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-if="user">
          <span class="user-email">{{ user.email }}</span>
          <v-btn
            outlined
            class="ml-4"
            @click="logout"
          >
            Logout
          </v-btn>
        </template>
      </v-app-bar>
      <v-dialog
        v-if="preview && invalidShortCodeURL"
        value="true"
        persistent
        transition="false"
        max-width="400"
        overlay-opacity="0.5"
        overlay-color="#333"
      >
        <v-alert type="warning" class="ma-0">Route not found</v-alert>
      </v-dialog>
      <!-- on mobile use tabs to switch between panel and map -->
      <v-tabs
        v-if="$vuetify.breakpoint.xs"
        v-model="mobileTab"
        :class="{
          'mobile-tabs': true,
          'elevation-4': true,
          'preview': preview
        }"
        background-color="grey lighten-4"
        fixed-tabs
      >
        <v-tab><v-icon class="mr-1">mdi-format-list-numbered</v-icon>Guidance</v-tab>
        <v-tab><v-icon class="mr-1">mdi-map</v-icon>Map</v-tab>
      </v-tabs>
      <v-dialog
        v-if="!preview && loginDialog && !user"
        v-model="loginDialog"
        persistent
        transition="false"
        max-width="400"
        overlay-opacity="1"
        overlay-color="#333"
      >
        <v-card>
          <v-card-title class="text-h5">
            Marleys Navigation Login
          </v-card-title>

          <v-card-text>
            <v-text-field
              v-model="email"
              label="Email"
              :rules="[rules.required]"
              :disabled="loginLoading"
            ></v-text-field>
            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="showPassword ? 'text' : 'password'"
              name="password"
              label="Password"
              @click:append="showPassword = !showPassword"
              @keyup.enter="email && password && login()"
              :disabled="loginLoading"
            ></v-text-field>
            <v-alert
              v-if="wrongPassword"
              dense
              outlined
              type="error"
            >
              Wrong password
            </v-alert>
            <v-alert
              v-if="loginError"
              dense
              outlined
              type="error"
            >
              {{ loginError }}
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              :loading="loginLoading"
              :disabled="!email || !password"
              @click="login"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="shareDialog"
        max-width="500"
      >
        <v-card>
          <v-card-title class="text-h5">
            Share with NavApp
          </v-card-title>

          <v-card-text v-if="shortCode" class="text-center mt-8">
            <v-container>
              <v-row justify="center" class="mb-4">
                <code class="text-h3">{{shortCode}}</code>
              </v-row>
              <v-row justify="center">
                <a :href="appLink">
                  <code class="text-h5">
                    {{appLink}}
                  </code>
                </a>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text v-else class="text-center mt-10 mb-15">
            <v-progress-circular
              indeterminate
              :size="50"
              color="primary"
            ></v-progress-circular>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="shareDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="saveDocError"
        color="red"
      >
        Error occurred, route not saved
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="saveDocError = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="saveDocSuccess"
        color="green"
        timeout="2000"
      >
        Saved route
      </v-snackbar>
      <div
        @dragenter="dragEntered = true"
        @dragleave="dragEntered = false"
        @dragover.prevent=""
        @drop.prevent="dropFile"
      >
        <v-fade-transition>
          <v-overlay
            v-if="dragEntered"
            color="#036358"
            class="drag-overlay"
          >
            Drop guidance XML file
          </v-overlay>
        </v-fade-transition>
        <div
          id="panel"
          v-show="!$vuetify.breakpoint.xs || mobileTab === 0"
          ref="panel"
          :class="{
            'panel': true,
            'mobile': $vuetify.breakpoint.xs,
            'desktop': !$vuetify.breakpoint.xs,
            'preview': preview
          }"
        >
          <div
            :class="{
              'panel-content': true,
              'pa-4': true,
              'preview': preview
            }"
          >
            <div v-if="preview">
              <v-progress-linear
                v-if="loadingRoute"
                class="my-10"
                height="10"
                striped
                indeterminate
              ></v-progress-linear>
              <div v-if="guidance" class="text-center text-overline">
              {{ guidance.MatchCode }}
              </div>
              <div v-if="guidance" class="text-center text-body-1">
              {{ guidance.Designation }}
              </div>
              <RouteInstructions
                v-if="!loadingRoute && routeInstructions"
                :legs="routeInstructions"
                :distance="routeLine?.properties?.distance"
                :duration="routeLine?.properties?.duration"
                @highlightManeuver="highlightManeuver"
                @unhighlightManeuver="unhighlightManeuver"
              ></RouteInstructions>
            </div>
            <div v-if="!preview && !guidance && !startNew">
              <h6 class="text-h6 ma-2">To start, either:</h6>
              <v-card outlined>
                <h5 class="text-subtitle-2 mt-2 ml-2">1. Load from file</h5>
                <v-file-input
                  label="Upload guidance XML"
                  class="mr-6"
                  @change="loadFile"
                  clear-icon="mdi-delete"
                ></v-file-input>
              </v-card>

              <v-card outlined>
                <h5 class="text-subtitle-2 mt-2 ml-2">2. Open using Short Code</h5>
                <v-text-field
                  v-model="shortCodeInput"
                  label="Short Code"
                  clearable
                  counter
                  :maxlength="MAX_SHORT_CODE_LENGTH"
                  :disabled="!mapLoaded"
                  class="shortCodeInput mx-6"
                  plain
                  type="text"
                  :loading="shortCodeInput"
                >
                <template v-slot:progress>
                  <v-progress-linear
                    v-if="shortCodeInput"
                    :value="shortCodeInputProgress"
                    :color="shortCodeInputColor"
                    absolute
                    height="4"
                  ></v-progress-linear>
                  </template>
                </v-text-field>
                <v-progress-circular
                  v-if="loadingShortCode"
                  class="ma-4"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-alert
                  v-if="invalidShortCodeInput"
                  class="ma-4"
                  type="error"
                >Short Code not found</v-alert>
              </v-card>

              <v-card outlined>
                <h5 class="text-subtitle-2 mt-2 ml-2">3. Create new from scratch</h5>
                <v-btn
                  block
                  class="mt-4"
                  @click="createNew"
                >New</v-btn>
              </v-card>
            </div>

            <div v-if="!preview && !guidance && startNew">
              <v-alert
                type="info"
              >Click on the map to set a Sender location</v-alert>
            </div>

            <v-alert
              v-if="xmlParseError"
              type="error"
            >Problem encountered reading Guidance XML</v-alert>
            <v-alert
              v-model="fileDropError"
              dismissible
              type="error"
            >File must be .xml</v-alert>
            <div v-if="!preview && guidance">
              <v-btn
                class="mb-4"
                block
                @click="clearRoute"
              >Clear Route</v-btn>

              <v-card elevation="0">
                <v-text-field
                  v-model="matchCode"
                  label="Matchcode"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="designation"
                  label="Designation"
                  outlined
                ></v-text-field>
              </v-card>
              <v-tabs
                v-model="tab"
                fixed-tabs
              >
                <v-tab>Waypoints</v-tab>
                <v-tab>Instructions</v-tab>
              </v-tabs>
              <v-tabs-items
                v-model="tab"
              >
                <v-tab-item>
                  <GuidanceList
                    v-if="guidance"
                    :guidance="guidance"
                    :waypoints="waypoints"
                    :waypointColors="waypointColors"
                    @removeWaypoint="removeWaypointFromList"
                    @hoverWaypoint="listHoverWaypoint"

                    :senderName="guidance.Sender?.name"
                    :senderMatchcode="guidance.Sender?.match_code"
                    :receiverName="guidance.Receiver?.name"
                    :receiverMatchcode="guidance.Receiver?.match_code"

                    @updateSenderName="updateSenderName"
                    @updateSenderMatchcode="updateSenderMatchcode"
                    @updateReceiverName="updateReceiverName"
                    @updateReceiverMatchcode="updateReceiverMatchcode"
                  >
                  </GuidanceList>
                </v-tab-item>
                <v-tab-item>
                  <v-progress-linear
                    v-if="loadingRoute"
                    class="my-10"
                    height="10"
                    striped
                    indeterminate
                  ></v-progress-linear>
                  <RouteInstructions
                    v-if="!loadingRoute && routeInstructions"
                    :legs="routeInstructions"
                    :distance="routeLine?.properties?.distance"
                    :duration="routeLine?.properties?.duration"
                    @highlightManeuver="highlightManeuver"
                    @unhighlightManeuver="unhighlightManeuver"
                  ></RouteInstructions>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </div>
          <div
            v-if="!preview"
            :class="{
            'fixed-bottom': true,
            'pa-6': true,
            'mobile': $vuetify.breakpoint.xs,
            'desktop': !$vuetify.breakpoint.xs
          }">
            <div v-if="shortCode" class="d-block text-center">
              <h5>Open In App</h5>
              <a :href="appLink">
                <code class="d-block text-h5">{{shortCode}}</code>
              </a>
            </div>
            <v-btn
              v-if="shortCode"
              class="mt-2"
              color="primary"
              block
              :disabled="!guidance || !unsavedChanges"
              prepend-icon="mdi-content-save"
              :loading="saving"
              @click="saveDoc({update: true})"
            >Save</v-btn>
            <v-btn
              v-else
              class="mt-2"
              color="primary"
              block
              :disabled="!guidance"
              @click="generateShortUrl"
            >Build Short URL</v-btn>
          </div>
        </div>
        <div
          id="map"
          ref="map"
          v-show="!$vuetify.breakpoint.xs || mobileTab === 1"
          :class="{
            'map': true,
            'preview': preview,
            'mobile': $vuetify.breakpoint.xs,
            'desktop': !$vuetify.breakpoint.xs
          }"
        >
          <v-card
            class="style-switcher ma-4"
            elevation="4"
            @click="toggleStyle"
          >
            <v-img
              v-if="mapStyle === 'Streets'"
              height="96"
              width="96"
              alt="Satellite Map"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              src="./assets/satellite.png"
            >
              <v-card-title class="text-subtitle-2 pa-0 justify-center">Satellite</v-card-title>
            </v-img>
            <v-img
              v-if="mapStyle === 'Satellite'"
              height="96"
              width="96"
              alt="Streets Map"
              class="black--text align-end"
              gradient="to bottom, rgba(255,255,255,.1), rgba(255,255,255,.5)"
              src="./assets/streets.png"
            >
              <v-card-title class="text-subtitle-2 pa-0 justify-center">Streets</v-card-title>
            </v-img>
          </v-card>
        </div>
        <div v-if="preview" :class="{
          'cta': true,
          'elevation-10': true,
          'blue-grey': true,
          'lighten-5': true,
          'pa-5': true,
          'mobile': $vuetify.breakpoint.xs
        }">
          <div v-if="shortCode" class="d-block text-center">
            <code class="d-block text-body-1">{{shortCode}}</code>
            <v-btn
              color="primary"
              :href="`marleys://go/${shortCode}`"
            >Open In App</v-btn>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
// Mapbox Libraries
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import MapboxDirections from '@mapbox/mapbox-sdk/services/directions'

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

// Components
import GuidanceList from '@/components/GuidanceList'
import RouteInstructions from '@/components/RouteInstructions'

// Utilities
import polyline from '@mapbox/polyline'
import convert from 'xml-js'
import { featureCollection as fc, point, lineString, feature } from '@turf/helpers'
import bbox from '@turf/bbox'
import nearestPointOnLine from '@turf/nearest-point-on-line'
import along from '@turf/along'

// Libraries
import config from '@/lib/config'
import chunk from '@/lib/chunk'
import createShortCode from '@/lib/createShortCode'
import matchCoordinates from '@/lib/matchCoordinates'
import { areSameCoordinates } from '@/lib/equal'

// Firebase (URL Shortner)
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { getFirestore, doc, getDoc, setDoc, updateDoc, serverTimestamp, GeoPoint } from 'firebase/firestore/lite'

const firebaseConfig = {
  apiKey: "AIzaSyCfyFqUIOyHBZTveb9HnibssKW7zsu1IbA",
  authDomain: "mapbox-364623.firebaseapp.com",
  projectId: "mapbox-364623",
  storageBucket: "mapbox-364623.appspot.com",
  messagingSenderId: "1000331025862",
  appId: "1:1000331025862:web:d2eb1102264e42cb5aa6c7",
  measurementId: "G-YMVFMEKRHK"
}

const SHORT_CODE_LENGTH = 6

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const analytics = getAnalytics(app)

mapboxgl.accessToken = config.accessToken

// Maximum number of waypoints a Mapbox Directions request can contain
const MAPBOX_DIRECTIONS_MAX_WAYPOINTS = 25

// pixel distance a waypoint must be dragged to be considered a drag instead of a click
const DRAG_PIXEL_THESHOLD = 6

const directionsService = MapboxDirections({
  accessToken: mapboxgl.accessToken,
  origin: mapboxgl.config.API_URL
})

const preview = window.location.pathname.startsWith('/go') || Object.fromEntries(new URLSearchParams(window.location.search).entries()).preview

let resolveMapLoaded
let resolveAppLoaded

const collection = 'routes'

export default {
  name: 'App',

  components: {
    GuidanceList,
    RouteInstructions
  },

  data: () => ({
    preview: preview,

    debug: Object.fromEntries(new URLSearchParams(window.location.search).entries()).debug,

    DIRECTIONS_MAX_WAYPOINTS: Number(Object.fromEntries(new URLSearchParams(window.location.search).entries()).limit) || MAPBOX_DIRECTIONS_MAX_WAYPOINTS,

    MAX_SHORT_CODE_LENGTH: SHORT_CODE_LENGTH,

    // mapboxgl.Map
    map: null,
    mapLoaded: false,

    onMapLoaded: new Promise((resolve) => {
      resolveMapLoaded = resolve
    }),

    startNew: false,

    // MapboxGeocoder
    geocoder: null,

    mapStyle: 'Streets',

    // mobile switch between map and guidance
    mobileTab: null,

    tab: null,

    // parsed XML guidance object
    /*
      Designation: "",
      MatchCode: "",
      Sender: {}
      Receiver: {},
      Guidance: [...Via]
    */
    guidance: null,

    // MatchCode Input
    matchCode: null,

    // Designation Input
    designation: null,

    // route waypoints including sender, vias and receiver
    waypoints: null,

    // route line Feature as returned from MapboxDirections
    routeLine: null,

    // route instructions as returned from Mapbox Directions
    routeInstructions: null,

    // distance along the route line of each waypoint
    waypointPositions: null,

    // hover is after waypoint N
    hoverAfterWaypoint: null,

    // if drag entered the window, show a drop overlay
    dragEntered: false,

    waypointColors: {
      'Via': 'via',
      'ViaSnapped': 'via'
    },

    listWaypointHover: null,

    // map bounds before highlighting a maneuver
    boundsBeforeHighlight: null,

    // are we highlighting a maneuver
    highlightingManeuver: false,

    // Short Code ID of saved route
    shortCode: null,

    // Short Code as input by the user
    shortCodeInput: null,

    shortCodeInputRules: {
      length: value => value.length === SHORT_CODE_LENGTH || `Must be ${SHORT_CODE_LENGTH} characters.`
    },

    loadingShortCode: false,
    invalidShortCodeInput: false,

    // toggle Short Code dialog
    shareDialog: false,

    // if there was an error parsing the Guidance XML
    xmlParseError: false,

    // if there was an error opening file drag dropped
    fileDropError: false,

    // if the route has unsaved changes
    unsavedChanges: true,

    // if the route is saving
    saving: false,

    // if there was an error saving the route
    saveDocError: false,

    // if the route was saved successfully
    saveDocSuccess: false,

    // debugging data
    directionRequestWaypoints: [],
    directionRequestRoutes: [],

    // waiting for Mapbox Directions request
    loadingRoute: false,

    // whole application loading
    loadingApp: true,

    onAppLoaded: new Promise((resolve) => {
      resolveAppLoaded = resolve
    }),

    // login
    loginDialog: true,
    showPassword: false, // show user unmasked password
    wrongPassword: false, // wrong password alert
    loginError: null,
    loginLoading: false, // while waiting username/password check
    email: null,
    password: null,
    rules: {
      required: v => !!v || 'Required',
    },
    // authenticated user
    user: null,

    invalidShortCodeURL: false
  }),

  async created () {
    // check if already logged in
    onAuthStateChanged(auth, user => {
      if (user) {
        this.user = user
        this.loginDialog = false
      } else {
        this.user = null
      }
      this.loadingApp = false
      resolveAppLoaded()
    })

    // grab Short Code from URL
    if (this.preview) {
      const parts = typeof this.preview === 'boolean' ? window.location.pathname.split('/') : ['', 'go', this.preview]
      if (parts?.length >= 3) {
        const shortCode = parts[2]

        this.loadingShortCode = true

        const docWithShortCode = await getDoc(doc(db, collection, shortCode.toUpperCase()))
        if (docWithShortCode.exists()) {
          const data = docWithShortCode.data()
          console.log(data)

          this.shortCode = shortCode
          this.loadFromDoc(data)
        } else {
          this.invalidShortCodeURL = true
        }
        this.loadingShortCode = false
      }
    }
  },

  mounted () {
    window.map = this.map = new mapboxgl.Map({
      container: 'map',
      style: config.style,
      center: config.center,
      zoom: config.zoom,
      bounds: config.initialBounds,
      hash: true
    })

    window.geocoder = this.geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      countries: 'au',
    })

    this.geocoder.on('result', () => {
      logEvent(analytics, 'geocoder_result')
    })

    if (!this.preview) {
      this.map.addControl(this.geocoder, 'top-left')
    }
    this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'top-right');

    this.map.on('load', () => {
      this.map.addSource('guidance', {
        type: 'geojson',
        data: fc([])
      })
      this.map.addLayer({
        id: 'waypoints',
        source: 'guidance',
        type: 'symbol',
        paint: {
          'text-color': 'white'
        },
        layout: {
          'symbol-z-order': 'viewport-y',

          // waypoint icon

          'icon-image': [
            'case',
            ['==', ['get', 'type'], 'Sender'], 'sender',
            ['==', ['get', 'type'], 'Receiver'], 'receiver',
            ['==', ['get', 'type'], 'Via'], 'via',
            ['==', ['get', 'type'], 'ViaSnapped'], 'via',
            'via'
          ],

          // never show text without the icon
          'icon-optional': false,
          // icons may overlap
          'icon-allow-overlap': true,
          // other symbols will not be shown if they overlap with the icon
          'icon-ignore-placement': false,

          // waypoint label

          'text-field': [
            'case',
            ['==', ['get', 'type'], 'ViaSnapped'],
            ['get', 'index'],
            ''
          ],
          'text-size': 14,
          'text-font': ['Open Sans Bold'],

          // other symbols will not be visible if they collide with the text
          'text-ignore-placement': false,
          // text will not be visible if the symbol overlaps another one
          'text-allow-overlap': false,
          // icon will show even without text
          'text-optional': true,
          'text-padding': 0,
        }
      })
      this.map.addLayer({
        id: 'via-hover',
        source: 'guidance',
        type: 'circle',
        filter: ['in', ['get', 'type'], ['literal', ['Sender', 'Receiver', 'ViaSnapped']]],
        paint: {
          'circle-radius': 15,
          'circle-opacity': this.debug ? 0.5 : 0
        }
      }, 'waypoints')

      this.map.addSource('route', {
        type: 'geojson',
        data: fc([])
      })

      this.map.addLayer({
        id: 'route',
        source: 'route',
        type: 'line',
        paint: {
          'line-color': this.$vuetify.theme.themes.light.route,
          'line-width': 4
        }
      }, 'streets-road-label')
      this.map.addLayer({
        id: 'route-case',
        source: 'route',
        type: 'line',
        paint: {
          'line-color': 'white',
          'line-width': 6
        }
      }, 'route')
      this.map.addLayer({
        id: 'route-shadow',
        source: 'route',
        type: 'line',
        paint: {
          'line-color': 'black',
          'line-opacity': 0.25,
          'line-blur': 2,
          'line-width': 6,
          'line-translate': [2, 2]
        }
      }, 'route-case')

      this.map.addSource('route-placeholder', {
        type: 'geojson',
        data: fc([])
      })
      this.map.addLayer({
        id: 'route-placeholder',
        source: 'route-placeholder',
        type: 'line',
        paint: {
          'line-color': '#000',
          'line-width': 4,
          'line-dasharray': [2, 4],
          'line-opacity': 0.7
        },
        layout: {
          'line-cap': 'round'
        }
      }, 'route')

      // invisible layer to create a snapped node on nearby mouse hover
      this.map.addLayer({
        id: 'route-hover',
        source: 'route',
        type: 'line',
        paint: {
          'line-opacity': this.debug ? 0.5 : 0,
          'line-width': 50
        },
        layout: {
          'line-cap': 'round',
          'line-join': 'round'
        }
      }, 'route-case')

      this.map.addSource('nearest', {
        type: 'geojson',
        data: fc([])
      })
      this.map.addLayer({
        id: 'nearest',
        source: 'nearest',
        type: 'circle',
        paint: {
          'circle-color': 'black',
          'circle-radius': 5,
          'circle-stroke-color': 'white',
          'circle-stroke-width': 1
        }
      }, 'waypoints')

      this.map.addSource('maneuver', {
        type: 'geojson',
        lineMetrics: true,
        data: fc([])
      })

      this.map.addLayer({
        id: 'maneuver',
        source: 'maneuver',
        type: 'line',
        paint: {
          'line-color': this.$vuetify.theme.themes.light.highlight,
          'line-width': 20,
          'line-gradient': [
            'interpolate',
            ['linear'],
            ['line-progress'],
            0, this.$vuetify.theme.themes.light.highlight,
            1, 'rgba(255, 255, 255, 0)'
          ]
        },
        layout: {
          'line-cap': 'round'
        }
      }, 'route-case')

      if (config.showMatchCoordinatesOnMap) {
        this.map.addSource('match-coordinates', {
          type: 'geojson',
          data: fc([])
        })
        this.map.addLayer({
          id: 'match-coordinates',
          source: 'match-coordinates',
          type: 'circle',
          paint: {
            'circle-color': 'orange',
            'circle-radius': 10,
            'circle-stroke-color': 'white',
            'circle-stroke-width': 1
          }
        })

      }

      if (config.showIntersectionsOnMap) {
        this.map.addSource('intersections', {
          type: 'geojson',
          data: fc([])
        })
        this.map.addLayer({
          id: 'intersections',
          source: 'intersections',
          type: 'circle',
          paint: {
            'circle-color': 'black',
            'circle-radius': 5,
            'circle-stroke-color': 'white',
            'circle-stroke-width': 1
          }
        })
      }

      if (config.showAnnouncementsOnMap) {
        this.map.addLayer({
          id: 'announcement',
          source: 'maneuver',
          type: 'symbol',
          paint: {
            'text-color': 'black',
            'text-halo-color': 'white',
            'text-halo-width': 2
          },
          layout: {
            'symbol-z-order': 'viewport-y',

            // announcement icon

            'icon-image': 'bullhorn-solid',

            // never show text without the icon
            'icon-optional': false,
            // icons may overlap
            'icon-allow-overlap': true,
            // other symbols will not be shown if they overlap with the icon
            'icon-ignore-placement': false,

            // announcement label

            'text-field': ['concat',
              ['to-string', ['get', 'index']],
              '. ',
              ['get', 'message']
            ],
            'text-size': 14,
            'text-font': ['Open Sans Regular'],

            // anchor first line to right, wrapping below
            'text-anchor': 'top-left',
            'text-offset': [1.5, 0.7],
            'text-justify': 'left',
            'text-max-width': 20,

            // other symbols will not be visible if they collide with the text
            'text-ignore-placement': false,
            // text will not be visible if the symbol overlaps another one
            'text-allow-overlap': false,
            // icon will show even without text
            'text-optional': true,
            'text-padding': 0,
          },
          filter: ['==', ['get', 'type'], 'announcement']
        })
      }

      if (config.showDirectionRequestsOnMap) {
        this.map.addSource('directions-requests', {
          type: 'geojson',
          data: fc([])
        })
        this.map.addLayer({
          id: 'directions-requests-routes',
          source: 'directions-requests',
          type: 'line',
          paint: {
            'line-color': [
                'interpolate-hcl',
                ['linear'],
                ['to-number', ['get', 'chunkIndex']
              ],
              0, '#e41a1c',
              1, '#377eb8',
              2, '#4daf4a',
              3, '#984ea3',
              4, '#ff7f00',
              5, '#ffff33',
              6, '#a65628',
              7, '#f781bf',
              8, '#999999'
            ],
            'line-width': 4,
            'line-offset': ['*', ['to-number', ['get', 'chunkIndex']], 8]
          },
          layout: {
          },
          filter: ['==', ['geometry-type'], 'LineString']
        })

        this.map.addLayer({
          id: 'directions-requests-waypoints',
          source: 'directions-requests',
          type: 'symbol',
          paint: {
            'text-color': 'black',
            'text-halo-color': 'white',
            'text-halo-width': 2
          },
          layout: {
            // icon
            'icon-image': 'streets-dot-11',

            // never show text without the icon
            'icon-optional': false,
            // icons may overlap
            'icon-allow-overlap': true,
            // other symbols will not be shown if they overlap with the icon
            'icon-ignore-placement': false,

            // announcement label

            'text-field': ['concat',
              ['to-string', ['get', 'chunkIndex']],
              ': ',
              ['to-string', ['get', 'waypointIndex']]
            ],
            'text-size': 14,
            'text-font': ['Open Sans Regular'],

            // anchor first line to right, wrapping below
            'text-anchor': 'left',
            'text-offset': ['match', ['to-number', ['get', 'chunkIndex'], 0],
              0, ['literal', [0.2, 0]],
              1, ['literal', [0.2, 0.5]],
              2, ['literal', [0.2, 1]],
              3, ['literal', [0.2, 1.5]],
              4, ['literal', [0.2, 2]],
              ['literal', [0.2, 0]]
            ],
            'text-justify': 'left',
            'text-max-width': 20,

            // other symbols will be visible even if they collide with the text
            'text-ignore-placement': true,
            // text will be visible even if the symbol overlaps another one
            'text-allow-overlap': true,
            // icon will show even without text
            'text-optional': false,
            'text-padding': 0,
          },
          filter: ['==', ['geometry-type'], 'Point']
        })
      }

      this.map.on('mousedown', 'route-hover', e => {
        const viaHover = this.map.queryRenderedFeatures(e.point, {
          layers: ['via-hover']
        })

        if (viaHover?.length) {
          // drag the via point
          this.dragVia = {
            originalEvent: e,
            feature: viaHover[0]
          }
          // start drag for via waypoint
          this.map.dragPan.disable()
        } else {
          // otherwise start drag for new via waypoint
          this.map.dragPan.disable()
        }
      })

      this.map.on('mousemove', e => {
        if (!this.map.dragPan.isEnabled()) {
          if (this.dragVia) {
            // move existing waypoint
            const index = this.dragVia.feature.properties.index
            this.waypoints[index].coordinates = e.lngLat.toArray()
            this.setData(this.waypoints, { fit: false, skipDirections: true })
          } else {
            // create a new waypoint
            this.map.getSource('nearest').setData(point(e.lngLat.toArray()))
            const placeholderLine = lineString([
              // prev via point
              this.waypoints[this.hoverAfterWaypoint].coordinates,
              // hover location
              e.lngLat.toArray(),
              // next via point
              this.waypoints[this.hoverAfterWaypoint + 1].coordinates,
            ])
            this.map.getSource('route-placeholder').setData(placeholderLine)
          }
        }
      })

      this.map.on('mouseup', (e) => {
        if (!this.map.dragPan.isEnabled()) {
          if (this.dragVia) {
            const dragDist = e.point.dist(this.dragVia.originalEvent.point)
            const index = this.dragVia.feature.properties.index

            if (dragDist < DRAG_PIXEL_THESHOLD) {
              // treat as a click, remove the waypoint
              // Sender/Receiver can't be removed
              if (this.dragVia.feature.properties.type === 'ViaSnapped') {
                this.removeWaypoint(index - 1)
              }
              logEvent(analytics, 'remove_waypoint', {
                trigger: 'map_click'
              })
            } else {
              // treat as a drag, move the waypoint
              this.waypoints[index].coordinates = e.lngLat.toArray()
              this.setData(this.waypoints, { fit: false })

              logEvent(analytics, 'move_waypoint')
            }
            this.dragVia = null

          } else {
            // insert new waypoint
            this.waypoints.splice(this.hoverAfterWaypoint + 1, 0, {
              type: 'Via',
              coordinates: e.lngLat.toArray()
            })
            this.setData(this.waypoints, { fit: false })
            this.map.getSource('route-placeholder').setData(fc([]))

            this.map.getSource('nearest').setData(fc([]))
            logEvent(analytics, 'insert_waypoint')
          }

          this.map.dragPan.enable()
        }
      })

      this.map.on('mouseenter', 'route-hover', () => {
        this.map.getCanvas().style.cursor = 'pointer'
      })
      this.map.on('mousemove', 'route-hover', e => {
        if (this.routeLine && this.map.dragPan.isEnabled()) {
          const nearest = nearestPointOnLine(this.routeLine, e.lngLat.toArray())
          this.map.getSource('nearest').setData(nearest)

          // from the nearest point along the line
          // determine which waypoint it is after
          // after 0 means after Sender but before next waypoint
          // after 1 means after first waypoint
          this.hoverAfterWaypoint = 0

          let hoverAfter = 0
          for (let i = 0; i < this.waypointPositions.length; i++) {
            if (nearest.properties.location > this.waypointPositions[i]) {
              // nearest is past this waypoint, continue to next waypoint position
              hoverAfter = i + 1
            } else {
              // nearest is before this waypoint
              break
            }
          }
          this.hoverAfterWaypoint = hoverAfter
        }
      })
      this.map.on('mouseleave', 'route-hover', () => {
        this.map.getSource('nearest').setData(fc([]))
        this.map.getCanvas().style.cursor = ''
      })

      this.map.on('click', (e) => {
        if (!this.guidance) {
          // no guidance, set Sender
          this.guidance = {
            Designation: '',
            MatchCode: '',
            Sender: {
              name: '',
              matchCode: '',
              coordinates: e.lngLat.toArray()
            },
            Guidance: []
          }

          // initially waypoints are provided from the Guidance object
          this.waypoints = [
            {
              type: 'Sender',
              coordinates: this.guidance.Sender.coordinates
            }
          ]

          this.onMapLoaded.then(() => {
            this.setData(this.waypoints, { fit: false })
          })

          logEvent(analytics, 'set_sender')
        } else if (this.guidance && this.guidance.Sender && !this.guidance.Receiver) {
          // Sender exists but no Receiver, set Receiver
          this.guidance.Receiver = {
            name: '',
            matchCode: '',
            coordinates: e.lngLat.toArray()
          }
          // initially waypoints are provided from the Guidance object
          this.waypoints.push(
            {
              type: 'Receiver',
              coordinates: this.guidance.Receiver.coordinates
            }
          )

          this.onMapLoaded.then(() => {
            this.setData(this.waypoints, { fit: false })
          })

          logEvent(analytics, 'set_receiver')
        }
      })

      resolveMapLoaded()
    })

    // get short_code from URL params
    this.onMapLoaded.then(() => {
      this.mapLoaded = true

      this.onAppLoaded.then(() => {
        this.shortCodeInput = Object.fromEntries(new URLSearchParams(window.location.search).entries()).short_code || null
      })
    })
  },

  methods: {
    openShortCode: async function (shortCode) {
      this.loadingShortCode = true

      const docWithShortCode = await getDoc(doc(db, collection, shortCode.toUpperCase()))
      if (docWithShortCode.exists()) {
        const data = docWithShortCode.data()
        console.log(data)

        this.shortCode = shortCode
        this.loadFromDoc(data)
      } else {
        this.invalidShortCodeInput = true
      }
      this.loadingShortCode = false
    },
    logout: function () {
      signOut(auth)
        .then(() => {
          this.user = null
          this.loginDialog = true
        })
        .catch(err => {
          console.log(err)
        })
    },
    login: function () {
      this.wrongPassword = false
      this.loginLoading = true
      this.loginError = null

      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(userCredential => {
          const user = userCredential.user
          this.user = user
          this.loginDialog = false
          this.loginLoading = false
          console.log(user)
        })
        .catch(err => {
          this.loginLoading = false

          if (err?.code === 'auth/wrong-password') {
            this.wrongPassword = true
          } else {
            this.loginError = err.message
          }
          console.log(err.code, err.message)
        })
    },
    updateSenderName: function (v) {
      this.guidance.Sender.name = v
      this.waypoints[0].name = v
    },
    updateSenderMatchcode: function (v) {
      this.guidance.Sender.match_code = v
      this.waypoints[0].match_code = v
    },
    updateReceiverName: function (v) {
      this.guidance.Receiver.name = v
      this.waypoints[this.waypoints.length - 1].name = v
    },
    updateReceiverMatchcode: function (v) {
      this.guidance.Receiver.match_code = v
      this.waypoints[this.waypoints.length - 1].match_code = v
    },
    clearRoute: function () {
      logEvent(analytics, 'clear_route')

      this.guidance = null
      this.waypoints = null
      this.designation = null
      this.matchCode = null
      this.routeInstruction = null
      this.routeLine = null
      this.shortCode = null
      this.waypointPositions = null
      this.xmlParseError = null
      this.shortCodeInput = null
      this.setData([], { fit: false })
      this.map.getSource('route').setData(fc([]))
      if (config.showIntersectionsOnMap) {
        this.map.getSource('intersections').setData(fc([]))
      }
      if (config.showMatchCoordinatesOnMap) {
        this.map.getSource('match-coordinates').setData(fc([]))
      }
      if (config.showDirectionRequestsOnMap) {
        this.map.getSource('directions-requests').setData(fc([]))
      }
      this.map.fitBounds(config.initialBounds)
      this.unsavedChanges = false

      this.startNew = false
    },
    createNew: function () {
      this.clearRoute = true
      this.shortCode = null
      this.startNew = true

      logEvent(analytics, 'create_new')
    },

    listHoverWaypoint: function (hover) {
      this.listWaypointHover = hover
    },
    getPadding: function (percent) {
      const boundingRect = this.map.getContainer().getBoundingClientRect()
      const paddingY = boundingRect.height * (percent !== undefined ? percent : config.paddingPercent)
      const paddingX = boundingRect.width * (percent !== undefined ? percent : config.paddingPercent)

      const padding = {
        top: paddingY,
        bottom: paddingY,
        left: paddingX,
        right: paddingX
      }

      return padding
    },
    highlightManeuver: function (step) {
      this.highlightingManeuver = true

      if (!this.boundsBeforeHighlight) {
        this.boundsBeforeHighlight = this.map.getBounds()
      }

      const geometryFeature = feature(polyline.toGeoJSON(step.geometry, config.POLYLINE_PRECISION), { type: 'geometry' })
      const announcements = step.voiceInstructions ?
        step.voiceInstructions.map((instruction, index) => {
          const point = along(geometryFeature, step.distance - instruction.distanceAlongGeometry, { units: 'meters' })
          point.properties.type = 'announcement'
          point.properties.index = index + 1
          point.properties.message = instruction.announcement
          return point
        })
      : []
      const geojson = fc([geometryFeature, ...announcements])
      this.onMapLoaded.then(() => {
        this.map.getSource('maneuver').setData(geojson)
      })
      this.map.fitBounds(bbox(geojson), {
        padding: this.getPadding(0.2),
        maxZoom: config.maneuverMaxZoom
      })

      logEvent(analytics, 'highlight_maneuver')
    },
    unhighlightManeuver: function () {
      this.highlightingManeuver = false

      this.onMapLoaded.then(() => {
        this.map.getSource('maneuver').setData(fc([]))
      })

      /*
      if (this.boundsBeforeHighlight && !e?.toElement?.parentElement?.classList.contains('v-list')) {
        this.map.fitBounds(this.boundsBeforeHighlight)
        this.map.on('zoomend', () => {
          if (!this.highlightingManeuver) {
            this.boundsBeforeHighlight = null
          }
        })
      }
      */
    },
    removeWaypointFromList: function (index) {
      this.removeWaypoint(index)

      logEvent(analytics, 'remove_waypoint', {
        trigger: 'panel_click'
      })
    },
    removeWaypoint: function (index) {
      this.waypoints.splice(index + 1, 1)
      this.setData(this.waypoints, { fit: false })
    },
    toggleStyle: function () {
      this.mapStyle = this.mapStyle === 'Streets' ? 'Satellite' : 'Streets'

      logEvent(analytics, 'set_map_style', {
        name: this.mapStyle
      })
    },
    setStyle: function (styleName) {
      const style = this.map.getStyle()
      const groups = {}
      for (const [id, group] of Object.entries(style.metadata['mapbox:groups'])) {
        const styleKey = group.name.replace(' Labels', '')
        if (!(styleKey in groups)) {
          groups[styleKey] = []
        }
        groups[styleKey].push(id)
      }

      for (const layer of style.layers) {
        if (layer?.metadata && layer.metadata['mapbox:group']) {
          if (styleName in groups && groups[styleName].includes(layer.metadata['mapbox:group'])) {
            this.map.setLayoutProperty(layer.id, 'visibility', 'visible')
          } else {
            this.map.setLayoutProperty(layer.id, 'visibility', 'none')
          }
        }
      }
    },
    saveDoc: async function (options) {
      this.saving = true

      const shortCode = this.shortCode

      const docProperties = {
        schema_version: 1,
        short_code: shortCode,
        environment: window.env,
        created: serverTimestamp(),

        // imported from XML
        send_date: this.guidance.SendDate || null,
        export_item_reference: this.guidance.ExportItemReference || null,

        matchcode: this.matchCode || null,
        designation: this.designation || null,

        // encode waypoint coordinates as Firebase.GeoPoint
        waypoints: this.waypoints.map(waypoint => {
          return {
            type: waypoint.type,

            match_code: waypoint.match_code || null,
            name: waypoint.name || null,

            // GeoJSON Coordinates [longitude, latitude]
            // GeoPoint (latitude, longitude)
            coordinates: new GeoPoint(waypoint.coordinates[1], waypoint.coordinates[0])
          }
        }),

        // extract intersection locations from routeInstructions response, encoded as Firebase.GeoPoint
        // intersection locations are used as BYOR via points as they provide less room for changes to the route compared with waypoints
        intersection_locations: this.routeInstructions
          ? this.routeInstructions
            .map(routeInstruction =>
              routeInstruction.steps
                .map(step =>
                  step.intersections
                    .map(intersection =>
                      new GeoPoint(intersection.location[1], intersection.location[0])
                    )
                )
            )
            .flat(3)
          : null,

        // match coordinates to use for Mapbox Map Matching query on the mobile client, encoded as Firebase.GeoPoint
        // these provide finer grained routing compared to waypoints and critically aren't too far spaced that Map Matching would return an error
        // they are also more spare compared with intersection locations, which means less Map Matching requests need to be sent by the mobile client for each route
        match_coordinates: this.routeInstructions
          ? matchCoordinates(this.routeInstructions)
            // convert to GeoPoint
            .map(coord => new GeoPoint(coord[1], coord[0]))
          : null,

        //route_instructions: this.routeInstructions ? JSON.stringify(this.routeInstructions) : null,
        //route_line: this.routeLine && this.routeLine.geometry && this.routeLine.geometry.coordinates ? this.routeLine.geometry.coordinates.map(coordinate => new GeoPoint(coordinate[1], coordinate[0])) : null
      }

      try {
        // full route not saved due to Mapbox Product terms
        // 2.5 Directions, Isochrone, Map Matching, Matrix and Optimization APIs. Customer shall not cache or store
        // results from the Directions, Isochrone, Map Matching, Matrix or Optimization APIs
        // instead only the intersection locations are saved to provide more exact routing over time compared to user defined waypoints

        const docRef = doc(db, collection, shortCode)

        if (options && options.update) {
          delete docProperties.created
          docProperties.updated = serverTimestamp()

          await updateDoc(docRef, docProperties)
        } else {
          await setDoc(docRef, docProperties)
        }
        this.saveDocSuccess = true
        this.saving = false
        this.unsavedChanges = false
      } catch (e) {
        this.saveDocError = true
        this.saving = false
        console.log(e)
      }
    },
    generateShortUrl: async function () {
      logEvent(analytics, 'build_short_url')

      this.shortCode = null
      this.shareDialog = true
      this.saveDocError = false
      this.saveDocSuccess = false
      this.saving = false

      try {
        // create new short code and check it does not exist yet
        let shortCode
        do {
          shortCode = createShortCode()
          const docWithShortCode = await getDoc(doc(db, collection, shortCode))
          if (docWithShortCode.exists()) {
            // short_code already exists
            shortCode = null
          } else {
            // short_code does not exist yet
          }
        } while (!shortCode)

        this.shortCode = shortCode

        this.saveDoc()
      } catch (e) {
        this.saveDocError = true
        this.saving = false
        console.log(e)
      }
    },
    dropFile: function (e) {
      this.dragEntered = false

      if (e.dataTransfer.items) {
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          if (e.dataTransfer.items[i].kind === 'file') {
            const file = e.dataTransfer.items[i].getAsFile()
            this.loadFile(file)
          }
        }
      } else {
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          const file = e.dataTransfer.files[i].getAsFile()
          this.loadFile(file)
        }
      }
    },
    loadFile: function (file) {
      this.fileDropError = false
      if (!file) {
        this.clear()
        return
      }

      if (file.type === 'application/xml' || file.type === 'text/xml') {
        file.text().then(text => {
          let doc
          try {
            doc = JSON.parse(convert.xml2json(text, { compact: true }))
          } catch (e) {
            console.log(e)
            this.xmlParseError = true
          }

          if (doc) {
            this.loadGuidance(doc)
          }
        })
      } else {
        this.fileDropError = true
      }

      logEvent(analytics, 'load_file')
    },
    loadFromDoc: function (data) {
      this.matchCode = data.matchcode
      this.designation = data.designation

      const sender = data.waypoints.length ? data.waypoints[0] : null
      const receiver = data.waypoints.length > 1 ? data.waypoints[data.waypoints.length - 1] : null
      const guidance = data.waypoints.length > 2 ? data.waypoints.slice(1, data.waypoints.length - 1) : null

      this.guidance = {
        SendDate: data.send_date,
        ExportItemReference: data.export_item_reference,
        MatchCode: data.matchcode,
        Designation: data.designation,
        Sender: sender,
        Receiver: receiver,
        Guidance: guidance
      }
      // initially waypoints are provided from the Guidance object
      this.waypoints = data.waypoints.map(waypoint => {
        const coordinates = [
          waypoint.coordinates.longitude,
          waypoint.coordinates.latitude
        ]
        waypoint.coordinates = coordinates
        return waypoint
      })

      this.onMapLoaded.then(() => {
        this.setData(this.waypoints, { fit: true })
      })

    },
    loadGuidance: function (doc) {
      const Header = doc?.AreaRelationData?.Header
      const AreaRelation = doc?.AreaRelationData?.AreaRelation

      const SendDate = Header?.SendDate._text
      const ExportItemReference = Header?.ExportItemReference._text

      const MatchCode = AreaRelation?.Matchcode?._text
      const Designation = AreaRelation?.Designation?._text

      const SenderAddress = (AreaRelation?.SenderArea?.Addresses && Array.isArray(AreaRelation?.SenderArea?.Addresses) && AreaRelation?.SenderArea?.Addresses.length) ?
        AreaRelation.SenderArea.Addresses[0] : AreaRelation?.SenderArea?.Addresses

      const Sender = {
        name: AreaRelation?.SenderArea?.Name1?._text,
        match_code: AreaRelation?.SenderArea?.Matchcode?._text,
        coordinates: SenderAddress && [
          Number(SenderAddress.GeoCoord?.Longitude?._text),
          Number(SenderAddress.GeoCoord?.Latitude?._text)
        ]
      }

      const ReceiverAddress = (AreaRelation?.ReceiverArea?.Addresses && Array.isArray(AreaRelation?.ReceiverArea?.Addresses) && AreaRelation?.ReceiverArea?.Addresses.length) ?
        AreaRelation.ReceiverArea.Addresses[0] : AreaRelation?.ReceiverArea?.Addresses

      const Receiver = {
        name: AreaRelation?.ReceiverArea?.Name1?._text,
        match_code: AreaRelation?.ReceiverArea?.Matchcode?._text,
        coordinates: ReceiverAddress && [
          Number(ReceiverAddress.GeoCoord?.Longitude?._text),
          Number(ReceiverAddress.GeoCoord?.Latitude?._text)
        ]
      }
      const Guidance = AreaRelation?.TransportGuidances?.ViaPoints ? AreaRelation.TransportGuidances.ViaPoints.map(point => {
        return [
          Number(point?.GeoCoord?.Longitude?._text),
          Number(point?.GeoCoord?.Latitude?._text),
        ]
      }) : null

      this.matchCode = MatchCode
      this.designation = Designation
      this.guidance = {
        SendDate,
        ExportItemReference,
        MatchCode,
        Designation,
        Sender,
        Receiver,
        Guidance
      }
      // initially waypoints are provided from the Guidance object
      this.waypoints = [
        {
          type: 'Sender',
          match_code: Sender.matchCode,
          name: Sender.name,
          coordinates: Sender.coordinates
        },
        ...Guidance.map(via => {
          return {
            type: 'Via',
            coordinates: via
          }
        }),
        {
          type: 'Receiver',
          match_code: Receiver.matchCode,
          name: Receiver.name,
          coordinates: Receiver.coordinates
        }
      ]

      this.onMapLoaded.then(() => {
        this.setData(this.waypoints, { fit: true })
      })
    },
    setData: function (waypoints, options) {
      const geojson = fc(waypoints.map((waypoint, i) => {
        return waypoint && point(waypoint.coordinates, {
          type: waypoint.type,
          index: i
        }, {
          id: i
        })
      }).filter(feature => !!feature))
      this.map.getSource('guidance').setData(geojson)

      if (options.fit) {
        this.map.fitBounds(bbox(geojson), {
          padding: this.getPadding()
        })
      }
      const directionsWaypoints = waypoints.map((waypoint, i) => {
        return {
          coordinates: waypoint.coordinates,
          /*
          approach: 'unrestricted',
          radius: 'unlimited',
          */
          silent: i !== 0 && i !== waypoints.length - 1
        }
      })

      this.map.setPaintProperty('route', 'line-color', this.$vuetify.theme.themes.light.disabled)

      if (options?.skipDirections || directionsWaypoints.length < 2) {
        return
      }

      this.loadingRoute = true
      this.fetchDirections({
        profile: 'driving',
        waypoints: directionsWaypoints,
        alternatives: false,
        overview: 'full',
        annotations: ['duration', 'distance', 'speed'],
        continueStraight: true,
        steps: true,
        language: 'en-AU',
        bannerInstructions: true,
        voiceInstructions: true,
        roundaboutExits: false,
        voiceUnits: 'metric',
        geometries: `polyline${config.POLYLINE_PRECISION}`
      })
        .then(directions => {
          this.loadingRoute = false
          if (config.showIntersectionsOnMap) {
            const intersections = directions.legs.map(leg => leg.steps.map(step => step.intersections.map(intersection => point(intersection.location)))).flat(2)
            this.map.getSource('intersections').setData(fc(intersections))
            console.log(`${intersections.length} intersection coordinates`)
          }

          this.map.getSource('route').setData(directions.route)
          this.map.setPaintProperty('route', 'line-color', this.$vuetify.theme.themes.light.route)

          this.routeInstructions = directions.legs
          if (config.showMatchCoordinatesOnMap) {
            const coordinates = matchCoordinates(directions.legs)
            this.map.getSource('match-coordinates').setData(fc(
              coordinates
                .map(coord => point(coord))
            ))
            console.log(`${coordinates.length} match coordinates`)
          }

          this.routeLine = directions.route
          const nearestPoints = waypoints.map(waypoint => {
            return nearestPointOnLine(directions.route, waypoint.coordinates)
          }).slice(1, waypoints.length - 1)
          this.waypointPositions = nearestPoints.map(point => point.properties.location)

          // snap vias back to the route line
          this.waypoints = waypoints.map((waypoint, i) => {
            if (waypoint.type === 'Via') {
              // snap via back to the route line
              waypoint.coordinates = directions.waypoints[i].location
              waypoint.type = 'ViaSnapped'
              return waypoint
            } else {
              return waypoint
            }
          })

          // re-set guidance from snapped waypoints
          const geojson = fc(this.waypoints.map((waypoint, i) => {
            return waypoint && point(waypoint.coordinates, {
              type: waypoint.type,
              index: i
            })
          }).filter(i => !!i))
          this.map.getSource('guidance').setData(geojson)
        })

    },
    /**
     * Fetches Mapbox Directions for a given chunk of waypoints, recursively requesting all subsequent chunks,
     * returning the accumulated response.
     *
     * @param {Array} chunks An array of waypoint chunks
     * @param {Number} chunkIndex The current chunk index to request
     * @param {Object} options Mapbox Directions getDirections options https://github.com/mapbox/mapbox-sdk-js/blob/main/docs/services.md#getdirections
     * @param {Object} acc The accumulated response
     *
     * The following code comments assume chunks is [[A, B, C, D], [D, E]]
     */
    fetchDirectionsChunk: function (chunks, chunkIndex, options, acc) {
      return new Promise((resolve, reject) => {
        const chunk = chunks[chunkIndex]

        // first and last waypoints cannot be silent
        chunk[0].silent = false
        chunk[chunk.length - 1].silent = false

        console.log('getDirections', chunkIndex, chunk)

        if (config.showDirectionRequestsOnMap) {
          const waypoints = chunk.map((waypoint, i) => point(waypoint.coordinates, { chunkIndex: chunkIndex, waypointIndex: i, silent: waypoint.silent }))
          this.directionRequestWaypoints.push(...waypoints)
          this.map.getSource('directions-requests').setData(fc([...this.directionRequestWaypoints, ...this.directionRequestRoutes]))
        }

        // since the last three steps mention the destination, they are unusable for any chunk except the last
        const NTH_LAST_STEP = 4

        // get directions
        directionsService.getDirections({
          profile: options.profile,
          waypoints: chunk,
          alternatives: options.alternatives,
          overview: options.overview,
          annotations: options.annotations,
          continueStraight: options.continueStraight,
          steps: options.steps,
          language: options.language,
          bannerInstructions: options.bannerInstructions,
          voiceInstructions: options.voiceInstructions,
          voiceUnits: options.voiceUnits,
          geometries: options.geometries
        })
          .send()
          .then(res => {
            if (res && res.body && res.body.code === 'Ok' && res.body.routes && res.body.routes.length) {
              const route = res.body.routes[0]
              const waypoints = res.body.waypoints

              if (chunkIndex !== 0) {
                // not the first chunk, remove the first step
                // since it was a repeat of the previous chunk and incorrectly mentions the departure
                const firstStep = route.legs[0].steps.shift()
                route.legs[0].duration -= firstStep.duration
                route.legs[0].distance -= firstStep.distance
                route.legs[0].weight -= firstStep.weight

                route.duration -= firstStep.duration
                route.distance -= firstStep.distance
                route.weight -= firstStep.weight

                const routeGeometry = polyline.decode(route.geometry, config.POLYLINE_PRECISION)
                const firstStepGeometry = polyline.decode(firstStep.geometry, config.POLYLINE_PRECISION)

                // for each point in the first step geometry, excluding the last one if more than one step
                for (let i = 0; i < firstStepGeometry.length - (route.legs[0].steps.length > 0 ? 1 : 0); i++) {
                  if (areSameCoordinates(firstStepGeometry[i], routeGeometry[0])) {
                    // remove point from routeGeometry
                    routeGeometry.shift()
                  } else {
                    break
                  }
                }
                route.geometry = polyline.encode(routeGeometry, config.POLYLINE_PRECISION)
              }

              if (chunkIndex < chunks.length - 1) {
                // not the last chunk, remove the last three steps

                // nthLastSteps to contain: [last, 2nd last, 3rd last]
                // if there are less than NTH_LAST_STEPs it will contain less
                const nthLastSteps = []
                for (let n = 1; n < NTH_LAST_STEP; n++) {
                  if (route.legs[0].steps.length) {
                    nthLastSteps.push(route.legs[0].steps.pop())
                  }
                }

                // remove these steps
                for (const step of nthLastSteps) {
                  route.legs[0].duration -= step.duration
                  route.legs[0].distance -= step.distance
                  route.legs[0].weight -= step.weight

                  route.duration -= step.duration
                  route.distance -= step.distance
                  route.weight -= step.weight
                }

                // recreate routeGeometry from remaining route leg steps
                const routeGeometry = route.legs[0].steps
                  .map(step => polyline.decode(step.geometry, config.POLYLINE_PRECISION))
                  .reduce((acc, cur) => {
                    if (
                      acc.length && cur.length &&
                      // last acc, matches first cur
                      areSameCoordinates(acc[acc.length - 1], cur[0])
                    ) {
                      cur.shift()

                      // try again as last step (arrival) will have two of the same points
                      if (
                        acc.length && cur.length &&
                        // last acc, matches first cur
                        areSameCoordinates(acc[acc.length - 1], cur[0])
                      ) {
                        cur.shift()
                      }
                    }

                    return [...acc, ...cur]
                  }, [])

                route.geometry = polyline.encode(routeGeometry, config.POLYLINE_PRECISION)
              }

              // current route result
              const cur = {
                waypoints,
                legs: route.legs,
                route: {
                  type: 'Feature',
                  properties: {
                    distance: route.distance,
                    duration: route.duration,
                  },
                  geometry: polyline.toGeoJSON(route.geometry, config.POLYLINE_PRECISION)
                }
              }
              if (config.showDirectionRequestsOnMap) {
                this.directionRequestRoutes.push(cur.route)
                cur.route.properties.chunkIndex = chunkIndex
                this.map.getSource('directions-requests').setData(fc([...this.directionRequestWaypoints, ...this.directionRequestRoutes]))
              }

              // update the accumulated results
              if (!acc) {
                // this must be the first request, just use the current route result
                acc = cur
              } else {
                // append current results (cur) to the previous results (acc)
                acc.waypoints = [...acc.waypoints, ...cur.waypoints]

                acc.legs[0].duration += cur.legs[0].duration
                acc.legs[0].distance += cur.legs[0].distance
                acc.legs[0].weight += cur.legs[0].weight

                acc.legs[0].steps = [...acc.legs[0].steps, ...cur.legs[0].steps]

                acc.route.properties.distance += cur.route.properties.distance
                acc.route.properties.duration += cur.route.properties.duration

                acc.route.geometry.coordinates.pop() // remove the last coordinate
                acc.route.geometry.coordinates = [...acc.route.geometry.coordinates, ...cur.route.geometry.coordinates]
              }

              if (chunkIndex >= chunks.length - 1) {
                // this was the last chunk
                resolve(acc)
              } else {
                // there are more chunks

                // start the next request from the previous last step,
                // then proceed to the subsequent waypoints after the step

                chunks[chunkIndex + 1][0].silent = true
                chunks[chunkIndex + 1].unshift({
                  coordinates: cur.legs[0].steps[cur.legs[0].steps.length - 1].maneuver.location,
                  silent: false
                })

                this.fetchDirectionsChunk(chunks, chunkIndex + 1, options, acc)
                  .then(result => {
                    resolve(result)
                  })
                  .catch(err => {
                    reject(err)
                  })
              }
            } else {
              reject()
            }
          }, err => {
            // handle error
            console.log(err)
            reject()
          })
      })
    },
    /**
     * Fetch directions from Mapbox Directions and return a promise that resolves with an object of structure:
     *  {
     *    waypoints,
     *    legs,
     *    route: {
     *      type: 'Feature',
     *      properties: {
     *        distance,
     *        duration
     *      },
     *      geometry: {
     *        type: 'LineString',
     *        coordinates: []
     *      }
     *    }
     *  }
     *
     * If the requested number of waypoints exceeds the 25 waypoints limit of the Directions API, this
     * method will transparently break up the request and re-join responses.
     *
     * @param {*} options Mapbox Directions getDirections options https://github.com/mapbox/mapbox-sdk-js/blob/main/docs/services.md#getdirections
     */
    fetchDirections: function (options) {
      this.directionRequestRoutes = []
      this.directionRequestWaypoints = []

      return new Promise((resolve, reject) => {
        if (options.waypoints.length > this.DIRECTIONS_MAX_WAYPOINTS) {
          /*
          The number of waypoints exceeds the MapboxDirections coordinates limit,
          therefore we must chunk into multiple requests.

          Ordinarily if you have coordinates A B C D and chunk into a maximum chunk of
          2 coordinates you'd have two requests: A B and C D. However the first chunk
          would then prematurely announce the destination at B.

          Take for example the road network,

          A                   B
          1 -- 2 -- 3 -- 4 -- 5

          where A and B are your waypoints, and 1 through 5 are the steps returned.
          Only steps 1 and 2 are usable for guidance instructions, therefore the
          steps 3 through 5 are discarded and need to be requested again. Since the
          first step will be a departure we need to make a request for 2 ... B and
          discarding the first step (2).

          Guidance instructions will mention something along the lines of:
            - Turn right, then your destination is on the left.
            - Your destination is on the left

          Chunks are of size DIRECTIONS_MAX_WAYPOINTS - 1 to allow room to add one
          extra coordinates before the start of the subsequent chunks
          */

          // chunk the waypoints
          // first chunk should be DIRECTIONS_MAX_WAYPOINTS, rest less one to allow for last leg start point to be inserted
          const chunks = chunk(options.waypoints, this.DIRECTIONS_MAX_WAYPOINTS, this.DIRECTIONS_MAX_WAYPOINTS - 1)

          // fetchDirectionsChunk will be called recursively for all the chunks and
          // will return the joined result
          this.fetchDirectionsChunk(chunks, 0, options)
            .then(result => {
              resolve(result)
            })
            .catch(err => {
              reject(err)
            })

        } else {
          // only single directions request needed
          directionsService.getDirections({
            profile: options.profile,
            waypoints: options.waypoints,
            alternatives: options.alternatives,
            overview: options.overview,
            annotations: options.annotations,
            continueStraight: options.continueStraight,
            steps: options.steps,
            language: options.language,
            bannerInstructions: options.bannerInstructions,
            voiceInstructions: options.voiceInstructions,
            voiceUnits: options.voiceUnits,
            geometries: options.geometries
          })
            .send()
            .then(res => {
              if (res && res.body && res.body.code === 'Ok' && res.body.routes && res.body.routes.length) {
                const route = res.body.routes[0]
                const waypoints = res.body.waypoints
                resolve({
                  waypoints,
                  legs: route.legs,
                  route: {
                    type: 'Feature',
                    properties: {
                      distance: route.distance,
                      duration: route.duration,
                    },
                    geometry: polyline.toGeoJSON(route.geometry, config.POLYLINE_PRECISION)
                  }
                })
              } else {
                reject()
              }
            }, err => {
              // handle error
              console.log(err)
              reject()
            })
        }
      })
    },
    clear: function () {
      this.map.getSource('route').setData(fc([]))
      this.map.getSource('guidance').setData(fc([]))
      if (config.showDirectionRequestsOnMap) {
        this.map.getSource('directions-requests').setData(fc([]))
      }
      this.guidance = null
      this.waypoints = null
    }
  },
  computed: {
    shortCodeInputProgress: function () {
      return Math.min(100, (this.shortCodeInput?.length || 0) / SHORT_CODE_LENGTH * 100)
    },
    shortCodeInputColor: function () {
      return ['yellow', 'green'][(this.shortCodeInput?.length || 0) < SHORT_CODE_LENGTH ? 0 : 1]
    },
    appLink: function () {
      return `https://nav.marleys.com.au/go/${this.shortCode}`
    }
  },
  watch: {
    '$vuetify.breakpoint.xs': function () {
      this.$nextTick(() => {
        this.map.resize()
      })
    },
    mobileTab: function () {
      this.$nextTick(() => {
        this.map.resize()
      })
    },
    email: function () {
      this.wrongPassword = false
      this.loginError = null
    },
    password: function () {
      this.wrongPassword = false
      this.loginError = null
    },
    tab: function (value) {
      logEvent(analytics, 'change_tab', {
        name: value
      })
    },
    shortCodeInput: function (shortCode, previous) {
      if (shortCode !== previous) {
        this.invalidShortCodeInput = false
      }

      if (shortCode && shortCode.length === SHORT_CODE_LENGTH) {
        logEvent(analytics, 'open_shortcode')

        this.openShortCode(shortCode)
      }
    },
    listWaypointHover: function (cur, prev) {
      if (prev) {
        // if previous value
        this.map.removeFeatureState({
          id: prev,
          source: 'guidance'
        }, 'hover')
      }

      if (cur !== null && cur !== undefined) {
        // cur value
        this.map.setFeatureState({
          id: cur,
          source: 'guidance'
        }, {
          hover: true
        })
      }
    },
    mapStyle: function (mapStyle) {
      this.setStyle(mapStyle)
    },

    // changes which should be saved
    matchCode: function () {
      this.unsavedChanges = true
    },
    designation: function () {
      this.unsavedChanges = true
    },
    guidance: {
      handler () {
        this.unsavedChanges = true
      },
      deep: true
    },
    waypoints: {
      handler () {
        this.unsavedChanges = true
      },
      deep: true
    },
    routeInstructions: {
      handler () {
        this.unsavedChanges = true
      },
      deep: true
    }
  }
};
</script>

<style>
html {
  overflow-y: auto !important;
}

#app {
  margin: 0;
}

body {
  margin: 0;
}

.v-app-bar {
  z-index: 2 !important;
}

.map {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-top: 104px; /* mobile-tabs + nav-bar */
}
.map.desktop {
  left: 350px;
  margin-top: 56px; /* nav-bar */
}

.panel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  overflow-y: scroll;
  margin-top: 104px; /* mobile-tabs + nav-bar */
}
.panel.desktop {
  width: 350px;
  margin-top: 56px; /* nav-bar */
}

.map.preview {
  margin-top: 48px;
  margin-bottom: 128px;
}
.map.desktop.preview {
  margin-top: 0px;
}
.panel.preview {
  margin-top: 48px;
  margin-bottom: 200px;
}
.panel.desktop.preview {
  margin-top: 0;
  margin-bottom: 128px;
}

.panel-content {
  margin-bottom: 155px;
}
.panel-content.preview {
  margin-bottom: 0px;
}

.mobile-tabs {
  position: absolute;
  z-index: 1;

  margin-top: 56px;
}
.mobile-tabs.preview {
  margin-top: 0;
}

.drag-overlay {
  pointer-events: none;
}

.cta {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 128px;
}
.cta.mobile {
  height: 200px;
}

/*
.v-timeline-item:hover {
  background: #eee;
}
*/

.style-switcher {
  position: absolute !important;
  bottom: 30px;
  left: 0;
  z-index: 1;
}
.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
  background: white;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20px);
}
.fixed-bottom.desktop {
  width: 336px;
}
.v-application code {
  background: none !important;
}

.shortCodeInput input {
  text-transform: uppercase;
}
.user-email {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
