<template>
  <div ref="guidance" v-if="guidance" class="mt-4 mx-2">
    <v-timeline class="pt-0 my-2 waypoints-timeline">
      <v-timeline-item
        v-if="guidance.Sender"
        right
        color="sender"
        class="pb-2"
        large
      >
        <v-text-field
          :value="senderName"
          @input="v => $emit('updateSenderName', v)"

          class="timeline-item-field my-2"
          label="Sender Name"
          outlined
          dense
        ></v-text-field>
        <v-text-field
          :value="senderMatchcode"
          @input="v => $emit('updateSenderMatchcode', v)"

          class="timeline-item-field my-2"
          label="Sender Matchcode"
          outlined
          dense
        ></v-text-field>
      </v-timeline-item>
      <v-timeline-item
        v-for="(waypoint, i) in (waypoints || []).slice(1, -1)"
        :key="i"
        right
        :color="waypoint.type in waypointColors ? waypointColors[waypoint.type] : 'black'"
        class="py-2"
      >
        <template v-slot:opposite>
          <v-btn
            text
            icon
            small
            @click="$emit('removeWaypoint', i)"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </template>
        <template v-slot:icon>
          <span
            class="font-weight-bold white--text"
            @mouseenter="$emit('hoverWaypoint', i)"
            @mouseleave="$emit('hoverWaypoint', null)"
          >{{i + 1}}</span>
        </template>
      </v-timeline-item>
      <v-timeline-item
        v-if="guidance.Receiver"
        right
        color="receiver"
        large
        class="pb-0"
      >
        <v-text-field
          :value="receiverName"
          @input="v => $emit('updateReceiverName', v)"

          class="timeline-item-field my-2"
          label="Receiver Name"
          outlined
          dense
        ></v-text-field>
        <v-text-field
          :value="receiverMatchcode"
          @input="v => $emit('updateReceiverMatchcode', v)"

          class="timeline-item-field my-2"
          label="Receiver Matchcode"
          outlined
          dense
        ></v-text-field>
      </v-timeline-item>
    </v-timeline>
    <v-divider class="my-4"></v-divider>
    <div class="text-caption text-center" v-if="waypoints">{{waypoints.length}} waypoints</div>
  </div>
</template>
<script>
export default {
  name: 'GuidanceList',
  props: {
    senderName: null,
    senderMatchcode: null,
    receiverName: null,
    receiverMatchcode: null,

    guidance: Object,
    waypoints: Array,
    waypointColors: Object
  },
  data: () => ({
  }),
}
</script>

<style>
.timeline-item-field .v-text-field__details {
  display: none;
}
.waypoints-timeline::before {
  top: 50px;
  bottom: 50px;
  height: auto;
}
</style>
