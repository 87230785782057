import { feature } from '@turf/helpers'
import lineChunk from '@turf/line-chunk'
import { getCoords } from '@turf/invariant'
import polyline from '@mapbox/polyline'

import config from '@/lib/config'

// given an array of route legs
// extract the first and last points and any leg segment points ensuring points are no more
// than the matching max distance apart
export default function (legs) {
  if (!legs) {
    return null
  }

  const coordinates = legs
    .map(leg =>
      leg.steps
        .map(step => {
          const geometry = feature(polyline.toGeoJSON(step.geometry, config.POLYLINE_PRECISION))

          // split into segments no greater than matching max distance
          const chunks = lineChunk(geometry, config.matchingMaxDistance, { units:  'meters'}).features

          // take the start point of each chunk
          const coords = chunks.map(chunk => {
            const firstCoord = getCoords(chunk)[0]
            return firstCoord
          })
          // and the last point of the last chunk
          const lastCoord = getCoords(geometry).pop()
          coords.push(lastCoord)

          return coords
        })
    )
    .flat(2)
    // remove consecutive duplicates
    .filter((coord, index, coords) => {
      return index === 0 || (coord[0] !== coords[index - 1][0] || coord[1] !== coords[index - 1][1])
    })

  return coordinates
}
