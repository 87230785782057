// chunk array into smaller arrays no longer than chunkSize
// while ensuring that the an array chunk always starts
// with the previous chunks ending element
//
// used to chunk Mapbox Directions requests
export default function (array, firstChunkSize, chunkSize) {
  if (chunkSize <= 0) {
    return [array]
  }

  const chunks = []

  for (let i = 0; i < array.length; i += ((i === 0 ? firstChunkSize : chunkSize) - 1)) {
    if (firstChunkSize - 1 <= 1 && i === 0) {
      continue
    }
    const chunk = array.slice(Math.max(i, 0), i + (i === 0 ? firstChunkSize : chunkSize))
    chunks.push(chunk)
  }

  return chunks
}
