export default {
  accessToken: 'pk.eyJ1IjoibWFybGV5MzMiLCJhIjoiY2w5eXQxOWRmMDhmMDNzb2F3ZXNhYTJjcyJ9.vHMd7pxxQdMWIPj9JAXzGw',
  style: 'mapbox://styles/marley33/cla6mvan7000o14mtsuyotlrn',
  center: [133.594, -27.956],
  zoom: 4,
  initialBounds: [[112.5813, -44.0283], [154.7730, -9.1111]],
  paddingPercent: 0.1,

  POLYLINE_PRECISION: 6, // must be 5 or 6

  maneuverMaxZoom: 18,
  // maximum distance between matching points
  // this must be less than the Map Matching API capability (currently 10,000m)
  // we include a buffer buffer to accommodate route length changes which may push the Mapbox calculated length beyond our calculation
  // this can happen if the route changes on the Mapbox end after we have saved the route
  matchingMaxDistance: 9000,
  showAnnouncementsOnMap: true,
  showAnnouncementsInPanel: true,


  // debug mode
  showIntersectionsOnMap: false,
  showMatchCoordinatesOnMap: false,
  showDirectionRequestsOnMap: false
}
