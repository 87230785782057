import humanizeDuration from 'humanize-duration'

export default function (durationInSeconds, options) {
  const durationInMilliseconds = durationInSeconds * 1000

  const formattedDuration = humanizeDuration(durationInMilliseconds, {
    units: options && options.includeSeconds ? ['d', 'h', 'm', 's'] : ['d', 'h', 'm'],
    round: true,
    maxDecimalPoints: 0,
    language: 'shortEn',
    languages: {
      shortEn: {
        d: c => ('day' + (c === 1 ? '' : 's')),
        h: c => ('hr' + (c === 1 ? '' : 's')),
        m: c => ('min' + (c === 1 ? '' : 's')),
        s: c => ('sec' + (c === 1 ? '' : 's'))
      }
    },
    delimiter: ' '
  })
  return formattedDuration
}
