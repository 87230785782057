<template>
  <div class="mt-4">
    <div class="text-center">
    {{formatDistance(distance)}} <strong>&middot;</strong> {{formatDuration(duration, { includeSeconds: false })}}
    </div>
    <v-list dense>
      <template
        v-for="(leg, i) in legs"
        >
        <v-list-item
          v-for="(step, j) in leg.steps"
          :key="[i, j].join('.')"
          @mouseenter="$emit('highlightManeuver', step)"
          @mouseleave="e => { $emit('unhighlightManeuver', e) }"
          class="px-0"
        >
          <v-list-item-avatar size="20">
            <v-img :src="require(`@/assets/directions-icons/${icon(step)}.svg`)"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="">
              {{step.ref}} {{step.name}}
            </v-list-item-title>
            <v-list-item-title class="font-italic font-weight-regular mb-1">
              {{step.maneuver.instruction}}
            </v-list-item-title>
            <v-list-item-subtitle v-if="step.distance || step.duration" class="font-weight-light">
              {{formatDistance(step.distance)}}
              <strong>&middot;</strong>
              {{formatDuration(step.duration, { includeSeconds: true })}}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="config.showAnnouncementsInPanel && step.voiceInstructions && step.voiceInstructions.length"
              class="mt-2 font-weight-light allow-wrap">
              <ol>
                <li
                  v-for="(instruction, i) in step.voiceInstructions"
                  :key="i"
                  class="mb-1">
                  <v-icon small color="blue" class="mb-2">mdi-bullhorn</v-icon>
                  {{instruction.announcement}}
                </li>
              </ol>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>
<script>
import formatDistance from '@/lib/formatDistance'
import formatDuration from '@/lib/formatDuration'

import config from '@/lib/config'

export default {
  name: 'RouteInstructions',
  props: {
    legs: Array,
    distance: Number,
    duration: Number
  },
  data: () => ({
    config: config
  }),
  methods: {
    formatDistance,
    formatDuration,
    icon: function (step) {
      if (step.maneuver.type === 'arrive' || step.maneuver.type === 'depart') {
        return step.maneuver.type
      }

      if (step.maneuver.type === 'roundabout' || step.maneuver.type === 'rotary') {
        return 'roundabout'
      }

      if (step.maneuver.modifier === 'uturn') {
        return 'uturn'
      }

      return 'turn_' + (step.maneuver.modifier ? step.maneuver.modifier.replace(/\s+/g, '_').toLowerCase() : step.maneuver.type.replace(/\s+/g, '_').toLowerCase())
    }
  }
}
</script>

<style scoped>
.allow-wrap {
  white-space: normal;
}
</style>
<style>
.v-list-item:hover {
  background: lightgrey;
}
</style>
