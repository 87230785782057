import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        brand: '#F44336',
        sender: '#4CAF50',
        via: '#2196F3',
        receiver: '#F44336',
        route: '#0D47A1',
        disabled: '#888888',
        highlight: '#81D4FA'
      },
    },
  },
});
